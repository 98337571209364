<template>
    <div class="body" ref="pdf" id="pdf">
        <iframe frameborder="0" target="_top"  :src="src">   
        </iframe>
    </div>
</template>

<script>
    var tid, tsid, timer;
    import { getCoursewareDetail, finishViewDocProcess } from '@/api/task.js';
    import { toast ,checkLogin } from '@/utils/common.js';
    // import { setWaterMark, removeWatermark } from "../utils/watermark";
    export default {
        data() {
            return{
                src: '',
                info: null,
                tsid: '',
                tid: '',
                cur: '',
                list: [],
                coursewareUser: null,
            }
            
        },
        activated() {
            window.vm = this;
            tid = this.tid = this.$route.query.id; // 任务id2
            tsid = this.tsid = this.$route.query.tsid;// 任务结果id
            this.cur = +this.$route.query.cur;// 当前课程索引
            checkLogin().then(()=> {
                this.getDetail(tid, tsid);
            })
            this.hideTab();
        },
        deactivated(){
            clearTimeout(timer);
            // removeWatermark();
        },
        methods:{
            getDetail(tid, tsid){
                getCoursewareDetail(tid, tsid).then((ret)=>{
                    var data = ret.data;
                    this.list = data.lessons;
                    this.info = data.lessons[this.cur];
                    this.coursewareUser = data.coursewareUser;
                    this.src = `https://view.officeapps.live.com/op/view.aspx?src=${this.info['file_url']}`;          
                    // this.getUser();
                    // 第一次看
                    if(this.info.clu){
                        return;
                    }
                    timer = setTimeout(()=>{
                        // finishViewDocProcess
                        var data = {
                            lesson: this.info.name,
                        };
                        var clid = this.info['id'];
                        finishViewDocProcess(tid, clid, data).then((ret)=>{
                            // 并不需要标记
                            console.log('已完成该课程', ret);
                        }).catch((err)=>{
                            toast(err.msg|| '更新总进度失败！', 'error')
                        })
                    },1000*60*1)
                }).catch((err)=>{
                    toast(err.msg || '获取课程失败！')
                });
            },
            hideTab(){
                //console.log(document.querySelector('#vantab'));
                document.querySelector('#vantab').classList.add('tab-hide');
            },
            onload(evt){
                console.log('已加载', evt);
            },
        }
    }
</script>

<style scoped>
    @import url('../../assets/css/common.css');
    iframe{
        width: 100%;
        min-height: 100vh;
    }
</style>